
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MBPApp } from '@govflanders/mbp-admin-panel-shared';

import ItemList from '@/components/shared/forms/ItemList.vue';
import StringList from '@/components/shared/forms/StringList.vue';

@Component({
  components: {
    StringList,
    ItemList,
  },
})
export default class MBPAppFeaturesBlock extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public value: MBPApp;

  public convertToNumbers(array: string): void {
    this.value[array] = this.value[array].map(el => (el !== '' ? parseInt(el, 10) : el));

    this.handleChange(this.value);
  }

  public handleChange(value: MBPApp): void {
    this.$emit('input', value);
  }
}
