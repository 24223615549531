
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MBPApp } from '@govflanders/mbp-admin-panel-shared';
import StringList from '@/components/shared/forms/StringList.vue';
import ContactOptionSelector from '@/components/shared/ContactOptionSelector.vue';
import draggable from 'vuedraggable';

@Component({
  components: {
    draggable,
    ContactOptionSelector,
    StringList,
  },
})
export default class MBPAppContactoptionsBlock extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public value: MBPApp;

  public get contactIds(): string[] {
    return this.value && this.value.contactIds ? [...this.value.contactIds] : [];
  }

  public set contactIds(newVal: string[]) {
    this.$emit('input', {
      ...this.value,
      contactIds: [...newVal],
    });
  }

  public handleAddElement(): void {
    // check if last element is empty
    if (this.contactIds.length > 0 && !this.contactIds[this.contactIds.length - 1]) {
      return;
    }

    this.$emit('input', {
      ...this.value,
      contactIds: [...this.contactIds, null],
    });
  }

  public handleRemoveElement(i): void {
    this.$emit('input', {
      ...this.value,
      contactIds: this.contactIds.filter((el, index) => index !== i),
    });
  }

  public handleInput(): void {
    this.$emit('input', {
      ...this.value,
      contactIds: [...this.contactIds],
    });
  }
}
