
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Contact } from '@govflanders/mbp-admin-panel-shared';
import { debounce } from 'lodash';

@Component
export default class ContactOptionSelector extends Vue {
  @Prop({
    type: String,
  })
  public value: string;

  public capiOptionsLoading = false;
  public capiOptions: Contact[] = [];

  public mounted() {
    if (!this.capiOptions.length) {
      this.loadcapiOptions(this.value);
    }
  }

  public get selectedContactOption(): Contact | string {
    return (
      (this.capiOptions && this.capiOptions.find(option => option.key === this.value)) || this.value
    );
  }

  public loadcapiOptions = debounce(this.getCapiOptionsForQuery, 300);

  public getCapiOptionsForQuery(query: string): void {
    if (query) {
      this.capiOptionsLoading = true;
      this.$store
        .dispatch('contactSearch/search', { query })
        .then(result => (this.capiOptions = result))
        .finally(() => (this.capiOptionsLoading = false));
    }
  }

  public handleInput(selectedOption: Contact) {
    this.$emit('input', selectedOption.key);
  }
}
