
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GenericLink } from '@govflanders/mbp-admin-panel-shared';

@Component
export default class LinksList extends Vue {
  @Prop({
    type: Array,
    required: false,
  })
  public value: GenericLink[] | null;

  @Prop({
    type: String,
    required: false,
  })
  public validatorRules: string;

  @Prop({
    type: String,
    required: true,
  })
  public addElementText: string;

  @Prop({
    type: [Array],
    required: true,
  })
  public linkTypes: string[];

  public get currentValue(): GenericLink[] {
    return this.value ? [...this.value] : [];
  }

  public handleAddElement(): void {
    // check if last element is empty
    if (this.currentValue.length > 0) {
      const lastElement = this.currentValue[this.currentValue.length - 1];
      if (lastElement.url === null || lastElement.url === '' || lastElement.label === '') {
        return;
      }
    }

    this.$emit('input', [...this.currentValue, { type: this.linkTypes[0], url: '', label: '' }]);
  }

  public handleRemoveElement(i): void {
    this.$emit(
      'input',
      this.currentValue.filter((el, index) => index !== i),
    );
  }
}
