var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mbpap-contact-option-selector"},[_c('vl-multiselect',{staticClass:"mbpap-contact-option-selector__selector",attrs:{"id":"capiKey","value":_vm.selectedContactOption,"options":_vm.capiOptions && _vm.capiOptions.length
        ? _vm.capiOptions
        : _vm.selectedContactOption
        ? [_vm.selectedContactOption]
        : [],"custom-label":capi =>
        capi && capi.key
          ? `${capi.key} - ${capi.name} ${capi.aanvrager ? '(' + capi.aanvrager + ')' : ''}`
          : capi,"loading":_vm.capiOptionsLoading,"placeholder":_vm.$t('widgets.configform.searchCapiPlaceholder'),"open-direction":"bottom","mod-multiple":false,"clear-on-select":false},on:{"search-change":_vm.loadcapiOptions,"input":function($event){return _vm.handleInput($event)}}}),(_vm.value)?_c('vl-link',{staticClass:"mbpap-contact-option-selector__edit-link",attrs:{"target":"_blank","to":{ name: 'contacts.edit', params: { id: _vm.value } },"title":_vm.$t('contacts.form.toContactOption'),"icon":"pencil-write","mod-icon-before":""}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }