
import { MBPApp } from '@govflanders/mbp-admin-panel-shared';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import JsonEditor from '@/components/shared/forms/JsonEditor.vue';
import { getMBPAppSchema } from '@/utility/mbp-app';

import MBPAppThemeLinksBlock from '@/components/mbp-app/form-blocks/MBPAppThemeLinksBlock.vue';
import MBPAppAcmBlock from './form-blocks/MBPAppAcmBlock.vue';
import MBPAppAppBlock from './form-blocks/MBPAppAppBlock.vue';
import MBPAppAppointmentBlock from './form-blocks/MBPAppAppointmentBlock.vue';
import MBPAppBrandingBlock from './form-blocks/MBPAppBrandingBlock.vue';
import MBPAppCodesBlock from './form-blocks/MBPAppCodesBlock.vue';
import MBPAppContactoptionsBlock from './form-blocks/MBPAppContactoptionsBlock.vue';
import MBPAppFeaturesBlock from './form-blocks/MBPAppFeaturesBlock.vue';
import MBPAppMessagesBlock from './form-blocks/MBPAppMessagesBlock.vue';
import MBPAppMetaDataBlock from './form-blocks/MBPAppMetaDataBlock.vue';
import MBPAppProfileBlock from './form-blocks/MBPAppProfileBlock.vue';
import MBPAppReportBlock from './form-blocks/MBPAppReportBlock.vue';
import MBPAppRssFeedBlock from './form-blocks/MBPAppRssFeedBlock.vue';
import MBPAppTilesBlock from './form-blocks/MBPAppTilesBlock.vue';
import MBPAppLocalJobsBlock from './form-blocks/MBPAppJobsBlock.vue';

@Component({
  components: {
    JsonEditor,
    MBPAppMetaDataBlock,
    MBPAppAppBlock,
    MBPAppThemeLinksBlock,
    MBPAppAcmBlock,
    MBPAppAppointmentBlock,
    MBPAppContactoptionsBlock,
    MBPAppBrandingBlock,
    MBPAppReportBlock,
    MBPAppFeaturesBlock,
    MBPAppCodesBlock,
    MBPAppRssFeedBlock,
    MBPAppTilesBlock,
    MBPAppProfileBlock,
    MBPAppMessagesBlock,
    MBPAppLocalJobsBlock,
  },
})
export default class MBPAppForm extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  public value: MBPApp;

  @Getter('app/env')
  public env: string;

  public schema: object;

  public created() {
    this.schema = getMBPAppSchema();
  }

  public get currentValue(): MBPApp {
    return this.value;
  }

  public set currentValue(value: MBPApp) {
    this.$emit('input', value);
  }
}
