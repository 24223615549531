
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MBPApp } from '@govflanders/mbp-admin-panel-shared';
import { Getter } from 'vuex-class';

import ImageUpload from '@/components/shared/forms/ImageUpload.vue';
import { ConfigValueName } from '@govflanders/mbp-admin-panel-shared';

@Component({
  components: {
    ImageUpload,
  },
})
export default class MBPAppAppBlock extends Vue {
  @Getter('config/get')
  private getConfig: (value: ConfigValueName) => string;

  public get mbpDirectory(): string {
    return this.getConfig(ConfigValueName.CLOUDINARY_FILE_PREFIX_MBPAPP);
  }

  public get appstoreConnectUrl(): string {
    return this.getConfig(ConfigValueName.APPSTORE_CONNECT_URL);
  }

  public get aasaUrl(): string {
    return this.getConfig(ConfigValueName.AASA_URL);
  }

  public get assetLinksUrl(): string {
    return this.getConfig(ConfigValueName.ASSETLINKS_URL);
  }

  public get playstoreUrl(): string {
    return this.getConfig(ConfigValueName.PLAYSTORE_URL);
  }

  @Prop({
    type: Object,
    required: false,
  })
  public value: MBPApp;

  @Prop({ default: () => Date.now().toString() })
  public readonly modalId: string;

  public setEntitySrc(src) {
    if (this.value.app) {
      this.value.app.icon = src;

      this.handleChange(this.value);
    }
  }

  public handleChange(value: MBPApp): void {
    this.$emit('input', value);
  }
}
