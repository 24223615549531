
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MBPApp } from '@govflanders/mbp-admin-panel-shared';

import UuidField from '@/components/shared/forms/UuidField.vue';

@Component({
  components: {
    UuidField,
  },
})
export default class MBPAppMetaDataBlock extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public value: MBPApp;

  public handleChange(value: MBPApp): void {
    this.$emit('input', value);
  }
}
