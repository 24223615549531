
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  Message,
  Platforms,
  MessageTypes,
  MessageActionTypes,
} from '@govflanders/mbp-admin-panel-shared';
import { v4 as uuidv4 } from 'uuid';
import MBPAppMessageBlock from '../form-blocks/MBPAppMessageBlock.vue';

@Component({
  components: {
    MBPAppMessageBlock,
  },
})
export default class MessagesList extends Vue {
  @Prop({
    type: Array,
    required: false,
  })
  public value: Message[] | null;

  @Prop({
    type: String,
    required: true,
  })
  public addElementText: string;

  public platforms = Object.values(Platforms);
  public messageTypes = Object.values(MessageTypes);
  public messageActionTypes = Object.values(MessageActionTypes);

  public get currentValue(): Message[] {
    return this.value ? [...this.value] : [];
  }

  public handleAddElement(): void {
    // check if last element is empty
    if (this.currentValue.length > 0) {
      const lastElement = this.currentValue[this.currentValue.length - 1];
      if (lastElement.id === '') {
        return;
      }
    }

    const messageObject = {
      id: uuidv4(),
      platform: this.platforms[0],
      type: this.messageTypes[0],
      from: null,
      to: null,
      appVersion: null,
      platformVersion: null,
      message: null,
      action: {
        label: null,
        url: null,
        type: this.messageActionTypes[0],
      },
    };

    this.$emit('input', [...this.currentValue, messageObject]);
  }

  public handleRemoveElement(i): void {
    this.$emit(
      'input',
      this.currentValue.filter((el, index) => index !== i),
    );
  }
}
