
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MBPApp } from '@govflanders/mbp-admin-panel-shared';

@Component({
  components: {},
})
export default class MBPAppLocalJobsBlock extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public value: MBPApp;
}
