
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MBPApp } from '@govflanders/mbp-admin-panel-shared';

@Component({
  components: {},
})
export default class MBPAppReportBlock extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public value: MBPApp;

  public handleChange(value: MBPApp): void {
    this.$emit('input', value);
  }
}
