import { Schema, Templates } from '@/json';
import { MBPApp } from '@govflanders/mbp-admin-panel-shared';

export function getMBPAppSchema(): object {
  return Schema.MBPApp;
}

export function getMBPAppTemplate(): MBPApp {
  return Object.assign({}, Templates.MBPApp as MBPApp);
}
