
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Link, MBPApp, Themes } from '@govflanders/mbp-admin-panel-shared';

import LinksList from '../components/LinksList.vue';

@Component({
  components: {
    LinksList,
  },
})
export default class MBPAppThemeLinksBlock extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public value: MBPApp;
  public linksTypes = Themes;

  public handleChange(changedLinkList: Link[]): void {
    this.$emit('input', { ...this.value, themeLinks: changedLinkList });
  }
}
