
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VlInputAddon, VlInputGroup } from '@govflanders/vl-ui-vue-components';

@Component({
  components: {
    VlInputAddon,
    VlInputGroup,
  },
})
export default class PasswordField extends Vue {
  @Prop({
    type: String,
    required: false,
  })
  public value: string;

  @Prop({
    type: String,
    required: false,
  })
  public label: string;

  @Prop({
    type: String,
    required: false,
  })
  public id: string;

  public isShown = false;

  public passwordChanged(event): void {
    this.$emit('input', event?.target?.value);
  }
}
