
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RssFeed } from '@govflanders/mbp-admin-panel-shared';

@Component
export default class RssFeedList extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  public value: RssFeed[];

  @Prop({
    type: String,
    required: false,
  })
  public validatorRules: string;

  @Prop({
    type: String,
    required: true,
  })
  public addElementText: string;

  public handleAddElement(): void {
    // check if last element is empty
    if (this.value.length > 0) {
      const lastElement = this.value[this.value.length - 1];
      if (lastElement.url === '' || lastElement.label === '' || lastElement.category === '') {
        return;
      }
    }

    this.value.push({ url: '', category: '', label: '' });
  }

  public handleRemoveElement(i): void {
    this.value.splice(i, 1);
  }
}
