
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import contrastChecker from 'color-contrast-checker';
import { MBPApp, ConfigValueName } from '@govflanders/mbp-admin-panel-shared';
import ColorField from '@/components/shared/forms/ColorField.vue';
import ImageUpload from '@/components/shared/forms/ImageUpload.vue';

@Component({
  components: {
    ColorField,
    ImageUpload,
  },
})
export default class MBPAppBrandingBlock extends Vue {
  @Getter('config/get')
  private getConfig: (value: ConfigValueName) => string;

  public get mbpDirectory(): string {
    return this.getConfig(ConfigValueName.CLOUDINARY_FILE_PREFIX_MBPAPP);
  }

  @Prop({
    type: Object,
    required: false,
  })
  public value: MBPApp;

  @Watch('value', { immediate: true })
  onAppConfigChange() {
    if (!this.value || !this.value.app) return;
    if (this.value.dashboardLogo === 'app-icon') {
      this.dashboardLogoType = 'app-icon';
      this.dashboardLogoUrl = this.value.app.icon;
    } else if (this.value.dashboardLogo) {
      this.dashboardLogoType = 'custom';
      this.dashboardLogoUrl = this.value.dashboardLogo;
    } else {
      this.dashboardLogoType = null;
      this.dashboardLogoUrl = null;
    }
  }

  readonly modalId = 'BrandingUploadModalId';
  dashboardLogoType = null;
  dashboardLogoUrl = null;

  get dashboardLogoPreviewUrl(): string {
    if (this.dashboardLogoType === 'custom') {
      return this.dashboardLogoUrl;
    } else if (this.dashboardLogoType === 'app-icon') {
      return this.value.app.icon;
    } else {
      return '';
    }
  }

  get brandingForegroundColor() {
    if (!this.value || !this.value.colors) return '#000';
    const bg = this.value.colors.action;
    const cc = new contrastChecker();
    return cc.isLevelCustom(bg, '#fff', 4) ? '#fff' : '#000';
  }

  public handleOverviewIconChange(type: '' | 'app-icon' | 'custom', src: string) {
    this.dashboardLogoType = type;
    this.dashboardLogoUrl = src;

    if (!this.value) return;

    if (type === 'custom') {
      this.value.dashboardLogo = src;
    } else if (type === 'app-icon' && this.value.app && this.value.app.icon) {
      this.value.dashboardLogo = 'app-icon';
    } else {
      this.value.dashboardLogo = null;
    }

    this.handleChange(this.value);
  }

  public handleChange(value: MBPApp): void {
    this.$emit('input', value);
  }
}
