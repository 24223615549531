
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MBPApp } from '@govflanders/mbp-admin-panel-shared';

@Component({})
export default class MBPAppProfileBlock extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public value: MBPApp;
}
