
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import {
  Message,
  Platforms,
  MessageTypes,
  MessageActionTypes,
} from '@govflanders/mbp-admin-panel-shared';
import UuidField from '@/components/shared/forms/UuidField.vue';

@Component({
  components: {
    UuidField,
  },
})
export default class MBPAppMessageBlock extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  public value: Message;
  @Prop({
    type: Number,
    required: true,
  })
  public i: number;

  public platforms = Object.values(Platforms);
  public messageTypes = Object.values(MessageTypes);
  public messageActionTypes = Object.values(MessageActionTypes);

  private actionType: MessageActionTypes | 'none' = this.value.action && this.value.action.type || 'none';

  @Watch('actionType', { immediate: true })
  onActionTypeChange() {
    if (this.actionType === 'none') {
      this.value.action = undefined;
    } else {
      this.value.action = this.value.action || { label: null, url: null, type: null };
      this.value.action.type = this.actionType;
    }
  }

  public handleMessageTypeChange(): void {
    if (this.value.type === 'info') {
      this.actionType = 'none';
    } else {
      this.actionType = this.messageActionTypes[0];
    }
  }

  public getDatePickerValue(message: Message, key: 'from' | 'to') {
    return [message[key] || null];
  }

  public setDatePickerValue(message: Message, key: 'from' | 'to', dates: [Date, Date]) {
    message[key] = dates && dates.length && dates[0] ? dates[0].toISOString() : null;
  }
}
